import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';

export interface ScrollEvent {
  scrollTop?: number;
  scrollLeft?: number;
  scrollHeight?: number;
  scrollWidth?: number;
}

// Bootstrap breakpoint
export const BREAKPOINT_XS = '(min-width: 0px) and (max-width: 575.98px)';
export const BREAKPOINT_SM = '(min-width: 576px) and (max-width: 767.98px)';
export const BREAKPOINT_XS_SM = '(min-width: 0px) and (max-width: 767.98px)';
export const BREAKPOINT_MD = '(min-width: 768px) and (max-width: 991.98px)';
export const BREAKPOINT_LG = '(min-width: 992px) and (max-width: 1199.98px)';
export const BREAKPOINT_XL = '(min-width: 1200px) and (max-width: 1399.98px)';
export const BREAKPOINT_XXL = '(min-width: 1400px)';

/**
 * UI 제어를 위한 클래스
 */
@Injectable({
  providedIn: 'root',
})
export class UiService {
  /**
   * app-root의 스크롤 이벤트 서브젝트
   */
  scrollEvent$: BehaviorSubject<ScrollEvent> = new BehaviorSubject({});

  /** 모바일 구분해서 표시할 때 사용 */
  isMobile$: Observable<boolean>;

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched(BREAKPOINT_XS);
  }

  device$: Observable<string>;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private breakpointObserver: BreakpointObserver,
  ) {
    // 모바일 기기 기준
    this.isMobile$ = this.breakpointObserver.observe([BREAKPOINT_XS]).pipe(
      map((r: any) => {
        return r.matches;
      }),
      shareReplay(1),
    );

    // breakpoint 크기별
    this.device$ = this.breakpointObserver
      .observe([BREAKPOINT_XS_SM, BREAKPOINT_MD, BREAKPOINT_LG])
      .pipe(
        map((state) => {
          if (state.breakpoints[BREAKPOINT_LG]) {
            return 'l';
          }

          if (state.breakpoints[BREAKPOINT_MD]) {
            return 'm';
          }

          if (state.breakpoints[BREAKPOINT_XS_SM]) {
            return 's';
          }

          return 'pc';
        }),
        shareReplay(1),
      );
  }

  /**
   * 크기별 표시 여부
   */
  isVisibleByBreakpoint(breakpoint?: string): boolean {
    // 조건 미설정은 표시
    if (!breakpoint) {
      return true;
    }

    let newBreakpoint = breakpoint;

    // breakpoint는 반드시 소괄호로 감싸야만 작동
    if (!newBreakpoint.startsWith('(')) {
      newBreakpoint = `(${newBreakpoint}`;
    }

    if (!newBreakpoint.endsWith(')')) {
      newBreakpoint = `${newBreakpoint})`;
    }

    return this.breakpointObserver.isMatched(newBreakpoint);
  }

  updateDocumentTitle(title?: string): void {
    let titleElement = this.document.head.querySelector('title');

    if (!titleElement) {
      titleElement = this.document.createElement('title');
      this.document.head.appendChild(titleElement);
    }

    titleElement.innerText = title!;
  }

  updateDocumentIcon(url?: string): void {
    let linkElement: HTMLLinkElement | null =
      this.document.head.querySelector('link[rel="icon"]');

    if (!linkElement) {
      linkElement = this.document.createElement('link');

      linkElement.rel = 'icon';
      linkElement.type = 'image/x-icon';

      this.document.head.appendChild(linkElement);
    }

    linkElement.href = url!;
  }
}
